import { createTheme } from "@mui/material/styles";

import {
  FaCloudRain,
  FaTemperatureHigh,
  FaLeaf,
  FaWater,
} from "react-icons/fa";

import { FaSunPlantWilt } from "react-icons/fa6";

const today = new Date();

export const initialCoreState = {
  primed: false,
  theme: createTheme({
    palette: {
      gold: {
        light: "#fffe76",
        main: "#fecb44",
        dark: "#c79b00",
        contrastText: "#fff",
      },
    },
  }),

  // Local Development
  apiURL: "https://98e2zjiicz.us-west-2.awsapprunner.com/",

  // Development API
  // apiURL: "https://dev.glam2.app/",

  // Production API
  // apiURL: "https://prod.glam2.app/",

  // vectorTileURL: "https://ffeo4p8pj8.execute-api.us-east-1.amazonaws.com/glam_vectors/",
  vectorTileURL: "https://9pde4bw326.execute-api.us-east-1.amazonaws.com",
  language: "en",
  tourState: 0,
  helpDrawer: false,
  assetsLoaded: false,
  productList: [],
  productIcons: {
    "chirps-precip": <FaCloudRain className="productIcon" color="#2185d0" />,
    "servir-4wk-esi": (
      <FaSunPlantWilt className="productIcon" color="#D4AC0D" />
    ),
    "servir-12wk-esi": (
      <FaSunPlantWilt className="productIcon" color="#D4AC0D" />
    ),
    "merra-2-max-temp": (
      <FaTemperatureHigh className="productIcon" color="#db2828" />
    ),
    "merra-2-min-temp": (
      <FaTemperatureHigh className="productIcon" color="#db2828" />
    ),
    "merra-2-mean-temp": (
      <FaTemperatureHigh className="productIcon" color="#db2828" />
    ),
    "mod09q1-ndvi": <FaLeaf className="productIcon" color="#21ba45" />,
    "myd09q1-ndvi": <FaLeaf className="productIcon" color="#21ba45" />,
    "mod13q1-ndvi": <FaLeaf className="productIcon" color="#21ba45" />,
    "myd13q1-ndvi": <FaLeaf className="productIcon" color="#21ba45" />,
    "mod13q4n-ndvi": <FaLeaf className="productIcon" color="#21ba45" />,
    "mod09a1-ndwi": <FaWater className="productIcon" color="#2185d0" />,
    "copernicus-swi": <FaWater className="productIcon" color="#2185d0" />,
    "vnp09h1-ndvi": <FaLeaf className="productIcon" color="#21ba45" />,
  },
  layerList: [],
  cropmaskList: [],
  variableList: [],
  isoList: [],
  announcements: true,
  annountmentList: [],
};

// ==============================|| CORE REDUCER ||============================== //

const coreReducer = (state = initialCoreState, action) => {
  switch (action.type) {
    case "primed":
      return {
        ...state,
        primed: action.value,
      };
    case "theme":
      return {
        ...state,
        theme: action.value,
      };
    case "language":
      return {
        ...state,
        language: action.value,
      };
    case "tourState":
      return {
        ...state,
        tourState: action.value,
      };
    case "helpDrawer":
      return {
        ...state,
        helpDrawer: action.value,
      };
    case "assetsLoaded":
      return {
        ...state,
        assetsLoaded: action.value,
      };
    case "productList":
      return {
        ...state,
        productList: action.value,
      };
    case "layerList":
      return {
        ...state,
        layerList: action.value,
      };
    case "cropmaskList":
      return {
        ...state,
        cropmaskList: action.value,
      };
    case "variableList":
      return {
        ...state,
        variableList: action.value,
      };
    case "isoList":
      return {
        ...state,
        isoList: action.value,
      };
    case "announcements":
      return {
        ...state,
        announcements: action.value,
      };
    case "announcementList":
      return {
        ...state,
        announcementList: action.value,
      };
    default:
      return state;
  }
};

export default coreReducer;
