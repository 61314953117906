const today = new Date();

export const initialExploreState = {
  productDate: today,
  activeProduct: "vnp09h1-ndvi",
  cropmaskState: false,
  cropmaskThreshold: 50,
  activeCropmask: "no-mask",
  anomalyState: false,
  anomalyType: "off",
  anomalyDuration: "5",
  anomalyDiffYear: 2021,
  layerLoading: true,
  activeBasemap: {
    key: "CartoDB: Light",
    name: "CartoDB Light",
    url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png",
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  },
  activeLabels: null,
  vectorTileLayers: [],
  geoJsonLayers: [],
  snapshot: {
    active: false,
    value: null,
  },
};

// ==============================|| EXPLORE REDUCER ||============================== //

const exploreReducer = (state = initialExploreState, action) => {
  switch (action.type) {
    case "productDate":
      return {
        ...state,
        productDate: action.value,
      };
    case "activeProduct":
      return {
        ...state,
        activeProduct: action.value,
      };
    case "cropmaskState":
      return {
        ...state,
        cropmaskState: action.value,
      };
    case "cropmaskThreshold":
      return {
        ...state,
        cropmaskThreshold: action.value,
      };
    case "activeCropmask":
      return {
        ...state,
        activeCropmask: action.value,
      };
    case "anomalyState":
      return {
        ...state,
        anomalyState: action.value,
      };
    case "anomalyType":
      return {
        ...state,
        anomalyType: action.value,
      };
    case "anomalyDuration":
      return {
        ...state,
        anomalyDuration: action.value,
      };
    case "anomalyDiffYear":
      return {
        ...state,
        anomalyDiffYear: action.value,
      };
    case "layerLoading":
      return {
        ...state,
        layerLoading: action.value,
      };
    case "activeBasemap":
      return {
        ...state,
        activeBasemap: action.value,
      };
    case "activeLabels":
      return {
        ...state,
        activeLabels: action.value,
      };
    case "vectorTileLayers":
      return {
        ...state,
        vectorTileLayers: action.value,
      };
    case "geoJsonLayers":
      return {
        ...state,
        geoJsonLayers: action.value,
      };
    case "snapshot":
      return {
        ...state,
        snapshot: action.value,
      };
    default:
      return state;
  }
};

export default exploreReducer;
